import Button from '../components/button';

const NotFound = () => {
	return (
		<div className="min-full sm:py-24 md:grid md:place-items-center">
			<div className="mx-auto max-w-max">
				<main className="sm:flex">
					<p className="text-4xl font-extrabold text-blue sm:text-5xl">404</p>
					<div className="sm:ml-6">
						<div className="sm:border-l sm:border-white sm:pl-6">
							<h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl">
								Page not found
							</h1>
							<p className="mt-1 text-base text-[#8a8d9e]">
								Please check the URL in the address bar and try again.
							</p>
						</div>
						<div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
							<Button
								text="Go back home"
								onClick={() => (window.location.href = 'https://waves.gg')}
							/>
							<Button text="Contact support" bgColor="bg-gray" />
						</div>
					</div>
				</main>
			</div>
		</div>
	);
};

export default NotFound;
